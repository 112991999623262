export const getMetaData = (routerPath) => {
  switch (true) {
    case routerPath === "/" || !routerPath:
      return {
        title: "NIDA นิด้า | คณะนิเทศศาสตร์และนวัตกรรมการจัดการ",
        description:
          "สร้างสรรค์ภูมิปัญญาแห่งนวัตกรรมเพื่อก้าวสู่สากล คณะนิเทศศาสตร์และนวัตกรรมการจัดการ (นิเทศนิด้า NIDA) พร้อมต่อยอดอนาคตทุกคนกับหลักสูตรปริญญาโทและปริญญาเอก",
        keyword: "นิเทศศาสตร์ NIDA",
        h1:
          "คณะนิเทศศาสตร์และนวัตกรรมการจัดการ สถาบันบัณฑิตพัฒนบริหารศาสตร์ (นิด้า NIDA)",
      };
    case routerPath.includes("/apply/master"):
      return {
        title:
          "สมัครเรียนปริญญาโท | คณะนิเทศศาสตร์และนวัตกรรมการจัดการ (NIDA นิด้า)",
        description:
          "เปิดรับสมัครนักศึกษาปริญญาโท สำหรับทุกหลักสูตรเพื่อพัฒนาต่อยอดองค์ความรู้ให้รอบรู้ในรอบด้าน ภายใต้คณะนิเทศศาสตร์และนวัตกรรมการจัดการ (NIDA นิด้า)",
        keyword: "สมัครเรียนปริญญาโท",
        h1: "สมัครเรียนปริญญาโท",
      };
    case routerPath.includes("/apply/doctoral"):
      return {
        title:
          "สมัครเรียนปริญญาเอก | คณะนิเทศศาสตร์และนวัตกรรมการจัดการ (NIDA นิด้า)",
        description:
          "เปิดรับสมัครนักศึกษาปริญญาเอก สำหรับทุกหลักสูตรเพื่อพัฒนาต่อยอดองค์ความรู้ให้รอบรู้ในรอบด้าน ภายใต้คณะนิเทศศาสตร์และนวัตกรรมการจัดการ (NIDA นิด้า)",
        keyword: "สมัครเรียนปริญญาเอก",
        h1: "สมัครเรียนปริญญาเอก",
      };
    case routerPath.includes("/scholarship"):
      return {
        title: "ทุนการศึกษา | คณะนิเทศศาสตร์และนวัตกรรมการจัดการ (NIDA นิด้า)",
        description:
          "เปิดรับสมัครนักศึกษาผ่านโครงการทุนการศึกษา ทั้งระดับปริญญาโทและปริญญาเอก คณะนิเทศศาสตร์และนวัตกรรมการจัดการ (NIDA นิด้า) ",
        keyword: "ทุนการศึกษา",
        h1: "ทุนการศึกษา",
      };
    case routerPath.includes("/course/master"):
      return {
        title:
          "หลักสูตรปริญญาโท | คณะนิเทศศาสตร์และนวัตกรรมการจัดการ (NIDA นิด้า)",
        description:
          "หลักสูตรปริญญาโท ส่งเสริมทักษะการสื่อสารเข้ากับนวัตกรรมและเทคโนโลยีในปัจจุบันเพื่อพัฒนาสังคมที่มีคุณภาพ ภายใต้คณะนิเทศศาสตร์และนวัตกรรมการจัดการ (NIDA นิด้า)",
        keyword: "หลักสูตรปริญญาโท",
        h1: "หลักสูตรปริญญาโท",
      };
    case routerPath.includes("/course/doctoral"):
      return {
        title:
          "หลักสูตรปริญญาเอก | คณะนิเทศศาสตร์และนวัตกรรมการจัดการ (NIDA นิด้า)",
        description:
          "หลักสูตรปริญญาเอก ส่งเสริมทักษะการสื่อสารเข้ากับการวิเคราะห์ข้อมูลในปัจจุบันเพื่อพัฒนาสังคมที่มีคุณภาพ ภายใต้คณะนิเทศศาสตร์และนวัตกรรมการจัดการ (NIDA นิด้า)",
        keyword: "หลักสูตรปริญญาเอก",
        h1: "หลักสูตรปริญญาเอก",
      };
    case routerPath.includes("/calendar/master"):
      return {
        title:
          "ตารางการศึกษาปริญญาโท | คณะนิเทศศาสตร์และนวัตกรรมการจัดการ (NIDA นิด้า)",
        description:
          "ตารางการศึกษาปริญญาโท สำหรับนักศึกษาปัจจุบัน ภายใต้คณะนิเทศศาสตร์และนวัตกรรมการจัดการ (NIDA นิด้า)",
        keyword: "ตารางการศึกษาปริญญาโท",
        h1: "ตารางการศึกษาปริญญาโท",
      };
    case routerPath.includes("/calendar/doctoral"):
      return {
        title:
          "ตารางการศึกษาปริญญาเอก | คณะนิเทศศาสตร์และนวัตกรรมการจัดการ (NIDA นิด้า)",
        description:
          "ตารางการศึกษาปริญญาเอก สำหรับนักศึกษาปัจจุบัน ภายใต้คณะนิเทศศาสตร์และนวัตกรรมการจัดการ (NIDA นิด้า)",
        keyword: "ตารางการศึกษาปริญญาเอก",
        h1: "ตารางการศึกษาปริญญาเอก",
      };
    case routerPath.includes("/student-scholarship"):
      return {
        title: "ทุนการศึกษา | คณะนิเทศศาสตร์และนวัตกรรมการจัดการ (NIDA นิด้า)",
        description:
          "โครงการทุนการศึกษา ทั้งระดับปริญญาโทและปริญญาเอก คณะนิเทศศาสตร์และนวัตกรรมการจัดการ (NIDA นิด้า) ",
        keyword: "ทุนการศึกษา",
        h1: "ทุนการศึกษา",
      };
    case routerPath.includes("/student-download"):
      return {
        title:
          "ดาวน์โหลดเอกสาร | คณะนิเทศศาสตร์และนวัตกรรมการจัดการ (NIDA นิด้า)",
        description:
          "ดาวน์โหลดเอกสาร สำหรับนักศึกษาปัจจุบัน ภายใต้คณะนิเทศศาสตร์และนวัตกรรมการจัดการ (NIDA นิด้า)",
        keyword: "ดาวน์โหลด",
        h1: "ดาวน์โหลดเอกสาร",
      };
    case routerPath.includes("/exam"):
      return {
        title:
          "สอบประมวลความรู้ (ปริญญาโท) | คณะนิเทศศาสตร์และนวัตกรรมการจัดการ (NIDA นิด้า)",
        description:
          "รายละเอียดการสอบประมวลความรู้ สำหรับนักศึกษาปริญญาโท ภายใต้คณะนิเทศศาสตร์และนวัตกรรมการจัดการ (NIDA นิด้า)",
        keyword: "สอบประมวลความรู้",
        h1: "สอบประมวลความรู้ (ปริญญาโท)",
      };
    case routerPath.includes("/qualification"):
      return {
        title:
          "สอบคุณสมบัติ (ปริญญาเอก) | คณะนิเทศศาสตร์และนวัตกรรมการจัดการ (NIDA นิด้า)",
        description:
          "รายละเอียดการสอบคุณสมบัติ สำหรับนักศึกษาปริญญาเอก ภายใต้คณะนิเทศศาสตร์และนวัตกรรมการจัดการ (NIDA นิด้า)",
        keyword: "สอบคุณสมบัติ",
        h1: "สอบคุณสมบัติ (ปริญญาเอก)",
      };
    case routerPath.includes("/studentwork"):
      return {
        title:
          "ผลงานนักศึกษา | คณะนิเทศศาสตร์และนวัตกรรมการจัดการ (NIDA นิด้า)",
        description:
          "ผลงานนักศึกษา จากนักศึกษาทั้งระดับปริญญาโทและปริญญาเอก ภายใต้คณะนิเทศศาสตร์และนวัตกรรมการจัดการ (NIDA นิด้า)",
        keyword: "ผลงานนักศึกษา",
        h1: "ผลงานนักศึกษา",
      };
    case routerPath.includes("/about-faculty"):
      return {
        title: "เกี่ยวกับคณะ | คณะนิเทศศาสตร์และนวัตกรรมการจัดการ (NIDA นิด้า)",
        description:
          "เกี่ยวกับคณะนิเทศศาสตร์และนวัตกรรมการจัดการ (NIDA นิด้า) เพื่อเป้าหมายในการสร้างสรรค์ภูมิปัญญา นวัตกรรมนิเทศศาสตร์ เพื่อก้าวสู่ระดับสากล",
        keyword: "คณะนิเทศศาสตร์ ",
        h1: "เกี่ยวกับคณะนิเทศศาสตร์ ",
      };
    case routerPath.includes("/partner"):
      return {
        title:
          "เครือข่ายความร่วมมือ | คณะนิเทศศาสตร์และนวัตกรรมการจัดการ (NIDA นิด้า)",
        description:
          "เครือข่ายความร่วมมือ จากสถาบันและองค์กรต่างๆที่ร่วมมือกับ คณะนิเทศศาสตร์และนวัตกรรมการจัดการ (NIDA นิด้า)",
        keyword: "เครื่อข่าย",
        h1: "เครือข่ายความร่วมมือ",
      };
    case routerPath.includes("/awards"):
      return {
        title:
          "รางวัลที่ได้รับ | คณะนิเทศศาสตร์และนวัตกรรมการจัดการ (NIDA นิด้า)",
        description:
          "รางวัลที่ได้รับและความภาคภูมิใจกับนักศึกษาที่ประสบความสำเร็จ ภายใต้คณะนิเทศศาสตร์และนวัตกรรมการจัดการ (NIDA นิด้า)",
        keyword: "รางวัล",
        h1: "รางวัลที่ได้รับ",
      };
    case routerPath.includes("/brand"):
      return {
        title:
          "อัตลักษณ์ของคณะ | คณะนิเทศศาสตร์และนวัตกรรมการจัดการ (NIDA นิด้า)",
        description:
          "อัตลักษณ์ของคณะนิเทศศาสตร์และนวัตกรรมการจัดการ (NIDA นิด้า) เพื่อเป้าหมายในการสร้างสรรค์ภูมิปัญญา นวัตกรรมนิเทศศาสตร์ เพื่อก้าวสู่ระดับสากล",
        keyword: "อัตลักษณ์ นิเทศศาสตร์ ",
        h1: "อัตลักษณ์ของคณะนิเทศศาสตร์ ",
      };
    case routerPath.includes("/news/faculty"):
      return {
        title:
          "ข่าวและกิจกรรมคณะ | คณะนิเทศศาสตร์และนวัตกรรมการจัดการ (NIDA นิด้า)",
        description:
          "ข่าวและกิจกรรมคณะ อัพเดทความเคลื่อนไหวต่างๆ ภายใต้คณะนิเทศศาสตร์และนวัตกรรมการจัดการ (NIDA นิด้า)",
        keyword: "ข่าวและกิจกรรม",
        h1: "ข่าวและกิจกรรมคณะ",
      };
    case routerPath.includes("/news/general"):
      return {
        title: "ประกาศทั่วไป | คณะนิเทศศาสตร์และนวัตกรรมการจัดการ (NIDA นิด้า)",
        description:
          "ประกาศทั่วไป สำหรับทั้งบุคลากร นักศึกษา และผู้สนใจจากคณะนิเทศศาสตร์และนวัตกรรมการจัดการ (NIDA นิด้า)",
        keyword: "ประกาศทั่วไป",
        h1: "ประกาศทั่วไป",
      };
    case routerPath.includes("/news/admission-result"):
      return {
        title: "ประกาศผล | คณะนิเทศศาสตร์และนวัตกรรมการจัดการ (NIDA นิด้า)",
        description:
          "ประกาศผลรับสมัครนักศึกษา ทุกระดับทั้งปริญญาโทและระดับปริญญาเอก ภายใต้คณะนิเทศศาสตร์และนวัตกรรมการจัดการ (NIDA นิด้า)",
        keyword: "ประกาศผลรับสมัคร",
        h1: "ประกาศผล",
      };
    case routerPath.includes("/news/interesting"):
      return {
        title: "สาระน่ารู้ | คณะนิเทศศาสตร์และนวัตกรรมการจัดการ (NIDA นิด้า)",
        description:
          "สาระน่ารู้ สร้างเสริมความรู้ดีๆเพื่อต่อยอดและปรับใช้กับชีวิตประจำวัน จากคณะนิเทศศาสตร์และนวัตกรรมการจัดการ (NIDA นิด้า)",
        keyword: "สาระน่ารู้",
        h1: "สาระน่ารู้",
      };
    // case routerPath.includes('/alumni'):
    //   return {
    //     title: 'ความคิดเห็นจากศิษย์เก่า | คณะนิเทศศาสตร์และนวัตกรรมการจัดการ (NIDA นิด้า)',
    //     description: 'ความคิดเห็นจากศิษย์เก่าที่จบการศึกษา ความในใจจากผู้เรียนถึง คณะนิเทศศาสตร์และนวัตกรรมการจัดการ (NIDA นิด้า)',
    //     keyword: 'ศิษย์เก่า',
    //     h1: 'ความคิดเห็นจากศิษย์เก่า'
    //   }
    case routerPath.includes("/alumninews"):
      return {
        title:
          "บทความศิษย์เก่า | คณะนิเทศศาสตร์และนวัตกรรมการจัดการ (NIDA นิด้า)",
        description:
          "บทความและบทสัมภาษณ์จากศิษย์เก่าที่จบการศึกษา จากคณะนิเทศศาสตร์และนวัตกรรมการจัดการ (NIDA นิด้า)",
        keyword: "ศิษย์เก่า",
        h1: "บทความศิษย์เก่า",
      };
    case routerPath.includes("/alumnilist"):
      return {
        title:
          "ศิษย์เก่าที่ประสบความสำเร็จ | คณะนิเทศศาสตร์และนวัตกรรมการจัดการ (NIDA นิด้า)",
        description:
          "รวบรวมความภาคภูมิใจภายใต้ความสำเร็จศิษย์เก่าที่จบการศึกษา จากคณะนิเทศศาสตร์และนวัตกรรมการจัดการ (NIDA นิด้า)",
        keyword: "ศิษย์เก่า",
        h1: "ศิษย์เก่าที่ประสบความสำเร็จ",
      };
    case routerPath.includes("/teachers"):
      return {
        title: "คณาจารย์ | คณะนิเทศศาสตร์และนวัตกรรมการจัดการ (NIDA นิด้า)",
        description:
          "รายชื่ออาจารย์ผู้ทรงคุณวุฒิ มากด้วยประสบการณ์และความเชี่ยวชาญ เบื้องหลังความสำเร็จของศิษย์ทุกคน จากคณะนิเทศศาสตร์และนวัตกรรมการจัดการ (NIDA นิด้า)",
        keyword: "อาจารย์",
        h1: "คณาจารย์",
      };
    case routerPath.includes("/staff"):
      return {
        title: "เจ้าหน้าที่ | คณะนิเทศศาสตร์และนวัตกรรมการจัดการ (NIDA นิด้า)",
        description:
          "รายชื่อเจ้าหน้าที่และตำแหน่ง จากคณะนิเทศศาสตร์และนวัตกรรมการจัดการ (NIDA นิด้า)",
        keyword: "เจ้าหน้าที่",
        h1: "เจ้าหน้าที่",
      };
    case routerPath.includes("/dissertation"):
      return {
        title:
          "วิทยานิพนธ์ และ งานวิจัย | คณะนิเทศศาสตร์และนวัตกรรมการจัดการ (NIDA นิด้า)",
        description:
          "วิทยานิพนธ์และงานวิจัย เพื่อสนับสนุนการเผยแพร่ความรู้ให้กับสังคม ต่อยอดปัญญาไปอย่างไม่รู้จบ จากคณะนิเทศศาสตร์และนวัตกรรมการจัดการ (NIDA นิด้า)",
        keyword: "วิทยานิพนธ์ งานวิจัย",
        h1: "วิทยานิพนธ์ และ งานวิจัย",
      };
    case routerPath.includes("/research"):
      return {
        title:
          "วิทยานิพนธ์ และ งานวิจัย | คณะนิเทศศาสตร์และนวัตกรรมการจัดการ (NIDA นิด้า)",
        description:
          "วิทยานิพนธ์และงานวิจัย เพื่อสนับสนุนการเผยแพร่ความรู้ให้กับสังคม ต่อยอดปัญญาไปอย่างไม่รู้จบ จากคณะนิเทศศาสตร์และนวัตกรรมการจัดการ (NIDA นิด้า)",
        keyword: "วิทยานิพนธ์ งานวิจัย",
        h1: "วิทยานิพนธ์ และ งานวิจัย",
      };
    case routerPath.includes("/conference"):
      return {
        title:
          "งานประชุมวิชาการระดับชาติ | คณะนิเทศศาสตร์และนวัตกรรมการจัดการ (NIDA นิด้า)",
        description:
          "รายละเอียดงานประชุมวิชาการระดับชาติ จากคณะนิเทศศาสตร์และนวัตกรรมการจัดการ (NIDA นิด้า)",
        keyword: "งานประชุมวิชาการ",
        h1: "งานประชุมวิชาการระดับชาติ",
      };
    case routerPath.includes("/national-conference"):
      return {
        title:
          "งานประชุมวิชาการระดับนานาชาติ | คณะนิเทศศาสตร์และนวัตกรรมการจัดการ (NIDA นิด้า)",
        description:
          "รายละเอียดงานประชุมวิชาการระดับนานาชาติ จากคณะนิเทศศาสตร์และนวัตกรรมการจัดการ (NIDA นิด้า)",
        keyword: "งานประชุมวิชาการ",
        h1: "งานประชุมวิชาการระดับนานาชาติ",
      };
    case routerPath.includes("/journal/communication-arts-and-innovation"):
      return {
        title:
          "วารสารวิชาการ | คณะนิเทศศาสตร์และนวัตกรรมการจัดการ (NIDA นิด้า)",
        description:
          "วารสารวิชาการ ส่งต่อความรู้ต่างๆเพื่อพัฒนาสังคมและคุณภาพชีวิตที่ดีขึ้น จากคณะนิเทศศาสตร์และนวัตกรรมการจัดการ (NIDA นิด้า)",
        keyword: "วารสาร",
        h1: "วารสารวิชาการ",
      };
    case routerPath.includes("/journal/communication-and-management"):
      return {
        title:
          "วารสารวิชาการ | คณะนิเทศศาสตร์และนวัตกรรมการจัดการ (NIDA นิด้า)",
        description:
          "วารสารวิชาการ ส่งต่อความรู้ต่างๆเพื่อพัฒนาสังคมและคุณภาพชีวิตที่ดีขึ้น จากคณะนิเทศศาสตร์และนวัตกรรมการจัดการ (NIDA นิด้า)",
        keyword: "วารสาร",
        h1: "วารสารวิชาการ",
      };
    case routerPath.includes("/knowledge"):
      return {
        title:
          "คลังความรู้นิเทศศาสตร์ | คณะนิเทศศาสตร์และนวัตกรรมการจัดการ (NIDA นิด้า)",
        description:
          "คลังความรู้นิเทศศาสตร์ หลากหลายความรู้เพื่อพัฒนาสังคมให้มีคุณภาพ จากคณะนิเทศศาสตร์และนวัตกรรมการจัดการ (NIDA นิด้า)",
        keyword: "นิเทศศาสตร์",
        h1: "คลังความรู้นิเทศศาสตร์",
      };
    case routerPath.includes("/annualreport"):
      return {
        title:
          "รายงานประจำปี | คณะนิเทศศาสตร์และนวัตกรรมการจัดการ (NIDA นิด้า)",
        description:
          "รายงานประจำปี Annual Report จากคณะนิเทศศาสตร์และนวัตกรรมการจัดการ (NIDA นิด้า)",
        keyword: "รายงานประจำปี",
        h1: "รายงานประจำปี",
      };
    case routerPath.includes("/contactus"):
      return {
        title: "ติดต่อเรา | คณะนิเทศศาสตร์และนวัตกรรมการจัดการ (NIDA นิด้า)",
        description:
          "ช่องทางการติดต่อ แผนที่ และการเดินทาง คณะนิเทศศาสตร์และนวัตกรรมการจัดการ (NIDA นิด้า)",
        keyword: "ติดต่อ",
        h1: "ติดต่อเรา",
      };
  }
};
